<template>
  <div class="item-detail">
    <div class="nav">
      <van-icon name="arrow-left" />
      <span @click="onBack">返回列表</span>
    </div>

    <!-- 内容展示区 -->
    <div
      v-if="curItem"
      class="show-box"
    >
      <video
        v-if="checkVideo(curItem.url)"
        :key="curItem.url"
        class="show-box_video"
        controls
        preload="auto"
        autobufer="true"
        webkit-playsinline
        playsinline
        x5-video-player-type="h5"
        :src="curItem.url"
      ></video>
      <!-- 图片 -->
      <div
        v-else
        class="show-box_img"
      >
        <van-image :src="curItem.url">
          <template v-slot:loading>
            <van-loading
              type="spinner"
              size="20"
            />
          </template>
        </van-image>
      </div>
    </div>

    <!-- 空态 -->
    <div
      v-if="!curItem"
      class="empty"
    >没有可展示的内容</div>

    <!-- 操作bar -->
    <div class="operate-bar">
      <!-- 按钮：上一个 -->
      <van-button
        plain
        type="primary"
        icon="arrow-left"
        color="#000"
        :disabled="switchBtnStatus.prev"
        @click="onPrevOne"
      ></van-button>
      <!-- 中间区域 -->
      <div
        v-if="curItem"
        class="operate-bar_btns"
      >
        <div
          v-if="showName"
          class="name"
        >{{ curItem.name }}</div>
        <template v-else-if="curItem.status === TO_BE_CHECK">
          <van-button
            class="btn"
            plain
            type="primary"
            color="#666"
            icon="delete-o"
            @click="onDelete"
          >删除</van-button>
          <van-button
            class="btn"
            type="primary"
            icon="orders-o"
            :color="color"
            @click="onPass"
          >检查通过</van-button>
        </template>
        <div
          v-else-if="curItem.status === PASSED"
          class="name"
        >检查已通过</div>
      </div>
      <!-- 占位 -->
      <div
        v-else
        class="operate-bar_btns"
      ></div>
      <!-- 按钮：下一个 -->
      <van-button
        plain
        type="primary"
        color="#000"
        icon="arrow"
        :disabled="switchBtnStatus.next"
        @click="onNextOne"
      ></van-button>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { TO_BE_CHECK, PASSED } from './constant'
import { checkVideo } from './config'

export default {
  components: {},
  props: {
    detailId: {
      type: [Number, String],
      default: 0,
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TO_BE_CHECK,
      PASSED,
      color: '#1989fa',

      edited: false,
      curIndex: this.list.findIndex(item => item.id === this.detailId),
      cacheList: [...this.list],
    }
  },
  computed: {
    curItem() {
      return this.cacheList[this.curIndex]
    },
    switchBtnStatus() {
      return {
        prev: this.curIndex <= 0,
        next: this.curIndex >= this.maxIndex || this.curIndex < 0,
      }
    },
    maxIndex() {
      return this.cacheList.length - 1
    },
  },
  created() {},
  mounted() {},
  methods: {
    onBack() {
      this.$emit('close', this.edited)
    },

    checkVideo,

    /**
     * @method 上一个 / 下一个
     */
    onPrevOne() {
      if (this.curIndex > 0) {
        this.curIndex -= 1
      }
    },
    onNextOne() {
      if (this.curIndex < this.maxIndex) {
        this.curIndex += 1
      }
    },

    /**
     * @method 删除 / 通过
     */
    onDelete() {
      const curId = this.curItem.id

      Dialog.confirm({
        title: '确认删除？',
        message: '该操作无法撤回。',
        confirmButtonText: '删除',
        confirmButtonColor: this.color,
      })
        .then(() => {
          this.$emit('delete', [curId], () => {
            this.cacheList = this.cacheList.filter(item => item.id !== curId)

            if (this.curIndex >= this.maxIndex + 1) {
              this.curIndex -= 1
            }

            this.edited = true
          })
        })
        .catch(() => {})
    },
    onPass() {
      Dialog.confirm({
        title: '确认通过？',
        message: '该操作无法撤回。',
        confirmButtonText: '通过',
        confirmButtonColor: this.color,
      })
        .then(() => {
          this.$emit('pass', [this.curItem.id], () => {
            this.curItem.status = PASSED
            this.edited = true
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="less">
.item-detail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 0 75px;
  overflow: hidden;
  background-color: #000;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.show-box {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 0;
}
.show-box_img {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.show-box_video {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.operate-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 15px 10px;
  background: #fff;
  border-top: 1px solid #efefef;
}
.operate-bar_btns {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 0;
  padding: 0 10px;

  .btn {
    margin: 0 10px;
  }

  .name {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
    line-height: 24px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.empty {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #fff;
  text-align: center;
  transform: translateY(-60px);
}
</style>
