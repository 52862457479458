<template>
  <div class="collect-data-list">
    <nav-bar @onBack="handleBack" />
    <div class="task-name">{{ urlParams.taskName }}</div>

    <!-- 没有管理权限且交付不合格时 -->
    <div v-if="!isHaveAuth && !isDeliveryQuality" class="notice">
      <van-icon name="volume-o" />
      <span class="detail-notice">
        请联系管理员确认质检情况后，再继续任务。
      </span>
    </div>

    <div class="content">
      <div class="title-content">
        <div class="content_count">
          <span v-if="isShowVideoNum">视频（{{ videoNum }}）</span>
          <span v-if="isShowImageNum">图片（{{ imageNum }}）</span>
          <span v-if="isShowFileNum">文件（{{ fileNum }}）</span>
        </div>
        <span v-if="!isDeliveryQuality" class="notice-word"> 质检不合格 </span>
      </div>
      <div v-if="userTaskCategoryNames.length" class="content-category">
        要求：{{ userTaskCategoryNames.join(" ") }}
      </div>

      <!-- 列表 -->
      <van-empty v-if="isEmpty" description="暂无数据" />
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="- - 到底了 - -"
        @load="onLoad"
      >
        <div v-for="(mapItem, mapIndex) in mapData" :key="mapIndex">
          <div class="notice-title">
            <div class="list-title" v-if="mapItem.submitDataType">
              {{ `${mapItem.submitDataType}（${mapItem.data.length}）` }}
            </div>
            <div class="notice-word" v-if="mapItem.qualityStatusMsg">
              {{ mapItem.qualityStatusMsg }}
            </div>
          </div>
          <div
            v-for="item in mapItem.data"
            :key="item.id"
            class="content_list_item"
            @click="onShowDetail(item.id)"
          >
            <div class="list_item_pic" :style="setPic(item.url)"></div>
            <div class="list_item_info">
              <div class="name">{{ item.name }}</div>
              <div class="category">
                <span
                  class="category-complete"
                  v-if="filterCategorys(item, CATEGORY_STATUS.COMPLETE)"
                >
                  {{ filterCategorys(item, CATEGORY_STATUS.COMPLETE) }}
                </span>
                <span
                  class="category-incomplete"
                  v-if="filterCategorys(item, CATEGORY_STATUS.INCOMPLETE)"
                >
                  {{ filterCategorys(item, CATEGORY_STATUS.INCOMPLETE) }}
                </span>
                <span
                  class="category-invalid"
                  v-if="filterCategorys(item, CATEGORY_STATUS.INVALID)"
                >
                  {{ filterCategorys(item, CATEGORY_STATUS.INVALID) }}
                </span>
              </div>
              <div class="bottom-info">
                <span class="date-time">{{ item.fileSubmitTime }}</span>
                <span
                  :class="{
                    'quality-msg': true,
                    green: item.submitDataQualityStatusMsg === '合格',
                    red: item.submitDataQualityStatusMsg === '不合格',
                  }"
                >
                  {{ item.submitDataQualityStatusMsg }}
                </span>
              </div>
            </div>
            <div
              v-if="showOperate"
              class="list_item_state"
              :class="{ 'is-highlight': item.status === TO_BE_CHECK }"
            >
              <span>{{ CHECK_STATUS_MAP[item.status] }}</span>
            </div>
          </div>
        </div>
      </van-list>

      <!-- C端管理员操作按钮 -->
      <div v-if="isShowControl" class="invalid-button">
        <van-button
          class="op-button op-left"
          icon="delete-o"
          color="#eb3223"
          @click="onDeleteAll(true)"
        >
          删除全部数据
        </van-button>
        <van-button
          class="op-button op-right"
          icon="success"
          color="#6cb836"
          @click="onPassAll(true)"
        >
          质检合格
        </van-button>
      </div>

      <!-- 操作栏 -->
      <div v-if="showBar && showOperate" class="operate-bar">
        <van-button
          class="btn-gap"
          plain
          type="primary"
          color="#666"
          icon="delete-o"
          @click="onDeleteAll(false)"
        >
          全部删除
        </van-button>
        <van-button
          class="btn-gap"
          type="primary"
          icon="orders-o"
          :color="color"
          @click="onPassAll(false)"
        >
          全部通过
        </van-button>
      </div>

      <!-- 详情弹层 -->
      <item-detail
        v-if="itemDetailStatus"
        :list="list"
        :detailId="itemDetailInitID"
        :show-name="!showOperate"
        @close="onCloseDetail"
        @delete="deleteData"
        @pass="passData"
      ></item-detail>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import NavBar from "../components/nav-bar.vue";
import ItemDetail from "./item-detail.vue";
import {
  getCollectDataList,
  checkApprove,
  checkDelete,
  querySubmitDataPicNum,
  deleteAllData,
  approveQuality,
} from "@/apis";
import { CHECK_STATUS_MAP, TO_BE_CHECK, CATEGORY_STATUS } from "./constant";
import { checkVideo, checkFile } from "./config";
import qs from "querystring";
import DefaultPicUrl from "../images/film.png";
import backListMixins from "../mixins/back-list";

export default {
  components: {
    NavBar,
    ItemDetail,
  },
  mixins: [backListMixins],
  props: {},
  data() {
    return {
      CHECK_STATUS_MAP,
      TO_BE_CHECK,
      CATEGORY_STATUS,
      color: "#3e7bf3",
      urlParams: qs.parse(location.search ? location.search.slice(1) : ""),

      mapData: [],
      videoNum: null,
      imageNum: null,
      fileNum: null,
      list: [],
      loading: false,
      finished: false,
      isEmpty: false,
      itemDetailStatus: false,
      itemDetailInitIndex: 0,
      flag: 0,
      isDeliveryQuality: true,
      isHaveAuth: false,
      isShowControl: false,
      userTaskCategoryNames: [],
    };
  },
  computed: {
    showBar() {
      return this.getWaitCheckListIds().length;
    },
    showOperate() {
      return this.urlParams.needCheck === "true";
    },
    isShowImageNum() {
      return this.imageNum !== null;
    },
    isShowVideoNum() {
      return this.videoNum !== null;
    },
    isShowFileNum() {
      return this.fileNum !== null;
    },
  },
  created() {},
  mounted() {
    this.getCount();
  },
  methods: {
    filterCategorys(item, type) {
      let categories = item.fileCategoryNames || [];

      switch (type) {
        case CATEGORY_STATUS.COMPLETE:
          categories = categories.filter(
            (i) => i.collectCorrect && i.collectComplete
          );
          break;

        case CATEGORY_STATUS.INCOMPLETE:
          categories = categories.filter(
            (i) => i.collectCorrect && !i.collectComplete
          );
          break;

        case CATEGORY_STATUS.INVALID:
          categories = categories.filter((i) => !i.collectCorrect);
          break;

        default:
      }

      return categories.map((m) => m.name).join(" ");
    },
    onLoad() {
      this.getCollectDataList();
    },
    filterList(data) {
      let list = [];
      data.forEach((item) => {
        list = [...list, ...item.data];
      });
      this.list = [...this.list, ...list];
    },
    joinMapData(data) {
      const len = this.mapData.length;
      if (
        len &&
        this.mapData[len - 1]?.submitDataType === data[0].submitDataType
      ) {
        this.mapData[len - 1].data.push(...data.splice(0, 1)[0].data);
      }
      this.mapData = [...this.mapData, ...data];
    },
    async getCollectDataList() {
      const lastItemIndex = this.list.length - 1;
      const lastItem = this.list[lastItemIndex];

      const res = await getCollectDataList({
        userTaskId: this.urlParams.userTaskId,
        lastId: lastItem ? lastItem.id : "",
      });

      this.loading = false;
      if (res.code === 0) {
        if (res.data?.data?.length) {
          const {
            deliveryQualityStatus = true,
            haveAuth = false,
            showControl,
            userTaskCategoryNames = [],
          } = res.data;
          this.isDeliveryQuality =
            deliveryQualityStatus === null ? true : deliveryQualityStatus;
          this.userTaskCategoryNames = userTaskCategoryNames;
          this.isHaveAuth = haveAuth || false;
          this.isShowControl = showControl;
          this.joinMapData(JSON.parse(JSON.stringify(res.data.data)));
          this.filterList(res.data.data);
        } else {
          this.finished = true;
        }

        this.isEmpty = this.list.length === 0;
      } else {
        Toast.fail(res.msg || "获取采集数据列表失败");
        this.finished = true;
      }
    },

    /**
     * @method 设置图片
     */
    setPic(url) {
      if (checkVideo(url)) {
        url = DefaultPicUrl;
      }

      if (checkFile(url)) {
        url = require("../images/file.png");
      }

      return {
        backgroundImage: `url(${url})`,
      };
    },

    /**
     * @method 获取资源数量
     */
    getCount() {
      querySubmitDataPicNum({
        userTaskId: this.urlParams.userTaskId,
      }).then((res) => {
        if (res.code === 0 && res.data) {
          this.setCount(res.data);
        } else {
          Toast.fail(res.msg || "获取数量失败");
        }
      });
    },
    /**
     * @method 更新页面上的数量展示
     */
    setCount(data) {
      this.imageNum = data.imageNum;
      this.videoNum = data.videoNum;
      this.fileNum = data.fileNum;
    },

    /**
     * @method 初始化
     */
    initListShow() {
      this.finished = false;
      this.loading = true;
      this.isDeliveryQuality = true;
      this.isHaveAuth = false;
      this.isShowControl = false;
      this.list = [];
      this.mapData = [];
      this.$nextTick(() => {
        this.onLoad();
      });
    },

    /**
     * @method 展示/隐藏 详情弹层
     */
    onShowDetail(id) {
      this.itemDetailStatus = true;
      this.itemDetailInitID = id;
    },
    onCloseDetail(edited) {
      this.itemDetailStatus = false;
      edited && this.initListShow();
    },

    /**
     * @method 删除数据
     */
    deleteData(idList, cb) {
      checkDelete({
        userTaskId: this.urlParams.userTaskId,
        idList,
      }).then((res) => {
        if (res.code === 0) {
          cb && cb();
          this.getCount();
        } else {
          Toast.fail(res.msg || "删除操作失败");
        }
      });
    },

    deleteInvalidAll(userTaskId, cb) {
      deleteAllData({
        userTaskId,
      }).then((res) => {
        if (res?.code === 0) {
          cb && cb();
          this.getCount();
        } else {
          Toast.fail(res?.message || "删除操作失败");
        }
      });
    },

    /**
     * @method 通过数据
     */
    passData(idList, cb) {
      checkApprove({
        userTaskId: this.urlParams.userTaskId,
        idList,
      }).then((res) => {
        if (res.code === 0) {
          cb && cb();
        } else {
          Toast.fail(res.msg || "通过操作失败");
        }
      });
    },

    approveAll(userTaskId, cb) {
      approveQuality({
        userTaskId,
      }).then((res) => {
        if (res?.code === 0) {
          cb && cb();
        } else {
          Toast.fail(res?.message || "一键合格操作失败");
        }
      });
    },

    /**
     * @method 获取待检查的数据id
     */
    getWaitCheckListIds() {
      return this.list
        .filter((item) => item.status === TO_BE_CHECK)
        .map((item) => item.id);
    },
    /**
     * @method 点击删除/通过 按钮
     */
    onDeleteAll(isCTask) {
      const msg = isCTask
        ? "删除后请联系作业人员重新上传数据。"
        : "该操作无法撤回，将删除所有“待检查”的文件。";
      const title = isCTask ? "确认删除？" : "全部删除？";
      Dialog.confirm({
        title: title,
        message: msg,
        confirmButtonText: "删除",
        confirmButtonColor: this.color,
      })
        .then(() => {
          if (isCTask) {
            this.deleteInvalidAll(this.urlParams.userTaskId, this.initListShow);
          } else {
            this.deleteData(this.getWaitCheckListIds(), this.initListShow);
          }
        })
        .catch(() => {});
    },
    onPassAll(isCTask) {
      const msg = isCTask
        ? "请确保您已检查【城市】【用户身份】均符合任务要求。\n通过后所有数据均保留，可继续上传。"
        : "该操作无法撤回，会将所有“待检查”的文件变为“检查通过状态”。";
      const title = isCTask ? "确认合格？" : "全部通过？";
      Dialog.confirm({
        title: title,
        message: msg,
        messageAlign: "left",
        confirmButtonText: "通过",
        confirmButtonColor: this.color,
      })
        .then(() => {
          if (isCTask) {
            this.approveAll(this.urlParams.userTaskId, this.initListShow);
          } else {
            this.passData(this.getWaitCheckListIds(), this.initListShow);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.collect-data-list {
  padding-bottom: 60px;
  font-size: 12px;
}
.task-name {
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;
}
.content {
  padding: 0 15px;
}
.notice {
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 15px;
  font-size: 13px;
  color: #d13622;
  background-color: #fbe8d5;
  .detail-notice {
    margin-left: 10px;
  }
}
.title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content_count {
    margin-top: 5px;
    color: #666;
  }
}

.notice-title {
  display: flex;
  align-items: center;
  .list-title {
    font-size: 18px;
    font-weight: 800;
    margin: 10px 0;
  }
}

.notice-word {
  color: #eb3223;
  margin-top: 5px;
}

.content_list_item {
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #efefef;
}
.list_item_pic {
  width: 50px;
  height: 50px;
  background-color: #d7d7d7;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.list_item_info {
  position: relative;
  flex: 1;
  width: 0;
  padding: 0 10px 20px 10px;

  .name {
    font-size: 14px;
    word-break: break-all;
  }
  .category {
    .category-complete {
      margin-right: 4px;
      color: lightgreen;
    }
    .category-incomplete {
      margin-right: 4px;
      color: orange;
    }
    .category-invalid {
      margin-right: 4px;
      color: red;
    }
  }
  .bottom-info {
    position: absolute;
    bottom: 0;
    left: 10px;
    .date-time {
      color: #999;
    }
    .quality-msg {
      &.red {
        color: red;
      }
      &.green {
        color: lightgreen;
      }
    }
  }
}
.list_item_state {
  display: flex;
  align-items: center;

  &.is-highlight {
    color: #3e7bf3;
  }
}
.invalid-button {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding-bottom: 20px;
  .op-button {
    border-radius: 16px;
  }
  .op-left {
    margin-left: 8px;
    width: 55%;
  }
  .op-right {
    margin-left: 5px;
    width: 40%;
  }
}
.operate-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #efefef;

  .btn-gap {
    margin: 0 10px;
  }
}
</style>
