// 状态 0：不需检查 2：待检查 3：检查完成
export const NO_CHECK = 0;
export const TO_BE_CHECK = 2;
export const PASSED = 3;
export const CHECK_STATUS_MAP = {
  [NO_CHECK]: "不需检查",
  [TO_BE_CHECK]: "待检查",
  [PASSED]: "检查通过",
};

// 品类采集状态
export const CATEGORY_STATUS = {
  COMPLETE: 1,
  INCOMPLETE: 2,
  INVALID: 3,
};
